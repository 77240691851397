import React from 'react';
import giff from '../images/Comp_4.gif';



function TopLogo() {

return (
        <div className='Signup'>
        <img src ={giff} style={{width:'350px',padding:'5px'}}
    alt = "loading..."/ >
     <h3>The PetSociety</h3> 
</div>
    )
}

export default TopLogo