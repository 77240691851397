import './App.css';
import Header from './components/header';
import Feature from './components/feature';
import Signup from './components/signup';
import Footer from './components/footer';
import TopLogo from './components/TopLogo';
import Instruction from './components/instruction';
import { Helmet } from 'react-helmet';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';



function App() {

    useEffect(() => {
        ReactGA.initialize('UA-197754388-1')
        ReactGA.pageview('/App')
  
    },[])


    return ( <div className = "App" >
        <TopLogo / >
        <Header / >
        <Feature / >
        <Instruction / >
        <Signup />
        <Footer / >
        <Helmet>
        <title>The PetSociety App</title>
        <meta name="description" content="building the future of social media , we are including pets to our virtual community" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>

        </div>

    );
}

export default App;