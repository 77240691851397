import React from 'react';
import ReactStoreBadges from 'react-store-badges'


function Signup(){
  
    return (
        <div class='Signup'>
            <h3>Download PetSociety Now !</h3>
        <ReactStoreBadges
          platform={'ios'}
          url={'https://apps.apple.com/us/app/the-pet-society/id1624305181'}
          locale={'en-us'}
        />
        
        <ReactStoreBadges
          platform={'android'}
          url={'https://play.google.com/store/apps/details?id=com.getwey.petsociety'}
          locale={'en-us'}
        />  
        </div>
    )
}

export default Signup