import React from 'react';
import logo from '../images/PET_SOCIETY_UPDATE_1.gif';

function Footer() {
    return (
        <div className='Signup'>
        <img src ={logo} class ="center" alt = "loading..."/ >
            <h6>The PetSociety Team</h6>
        </div>

        
    )
}

export default Footer