import React from 'react'

function instruction() {
    return (
        <div className='instruction'>
            <h2>What is Petsociety?</h2>
            <p>
            PetSociety is a Social Media Platform that lets you include your pets and connect with other pet owners!, We have revolutionary new profile layouts and created a feature that solves the problem of including your pets online. With the PetSociety app you can:<br></br>

- Setup profiles for multiple pets<br></br>
- Connect with friends<br></br>
- Post pictures and videos of your pets and they will show on the unique pet pages<br></br>
- Introducing LiveWalk! Dog owners can share your walk with friends via live video stream<br></br>
- Get access to community groups within your area to learn about the best veterinarians, pet resources, pet friendly businesses, etc<br></br>
- Arrange events and activities<br></br>
- Post and comment on profiles<br></br>

More features are being developed all the time as we revolutionize social media with our pets<br></br>
            </p>
        </div>
    )
}

export default instruction
